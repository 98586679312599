<template lang="pug">
main
  header(id="header-2024" class="fixed-top")
    div(class="container header-2024")
      div(class="logo")
        div(class="logo-wrap-wuwow")
          a(href="https://www.wuwowjr.tw/" target="_blank" class="scrollto")
            img(src="@/assets/newYear_2024/wuwow-jr-logo.svg" alt="wuwow-jr-logo" class="img-fluid")
      div(class="logo_text")
        a(href="https://www.wuwowjr.tw/" target="_blank")
          div 0800 885 315 幫幫我 上英文 | 撥打專線，立即有專人為您服務
  Banner
  TwoActive
  TestYourRep
  FeatureComparison
  Ribbons
  .gifts
    .gifts-wrap
      .bg-grid
        .gifts-title
          img(src="@/assets/anniversary_2023/gifts/loudspeaker.svg" alt="loudspeaker")
          h2 好禮領取搶先看
        .tabs
          ul
            //- li(:class="{active:activeTab === 'activeTab'}")
            //-   button(type="button" @click="activeTab = 'activeTab'")
            //-     | 英文能力
            //-     br.d-sm-none
            //-     | 分析報告
            li(:class="{active:activeTab === 'books'}")
              button(type="button" @click="activeTab = 'books'")
                | 家長必讀雙語
                br.d-sm-none
                | 兒童養成辦法
            li(:class="{active:activeTab === 'topics'}")
              button(type="button" @click="activeTab = 'topics'")
                | 劍橋兒童
                br.d-sm-none
                | 英檢題庫
        .gifts-lightbox
          LightBox(:activeTab='activeTab' v-show='[ "books" ].includes(activeTab)')
          LightBox(:activeTab='activeTab' v-show='[ "topics" ].includes(activeTab)')

      Carousel
      .note
        h2 活動注意事項
        .note-item
          //- h3 一、抽獎資格相關問題
          p 1.活動期間 2024/2/1-2024/2/29。
          p 2.活動期間內購買 WUWOW JUNIOR 課程可免費享有 1 年 Disney+ 標準版；若於合約期滿結束前退費解約則六書堂數位學習股份有限公司將追回此費用。
          p 3. 贈品不得轉售(贈)、要求折價、變現，或要求其他優惠方式。
          p 4. 符合活動資格者，將於合約開通後收到確認電子郵件，請於 2024/3/20 前依信件內容說明進行操作、請款，活動採實報實銷，逾期恕不受理。
          p 5. 贈品領取相關問題，請來信 md6@lioshutan.com 並附上您的姓名/手機/E-mail，將有專人協助。
          p 6.活動僅適用新春專案。
          p 7.本公司保有解釋、修改、終止本活動之權利。

  footer
    ul
      li
        a(href="mailto:service@wuwow.tw")  聯絡信箱  service@wuwow.tw
      li
        a(href="tel:0800-885-315") 免費諮詢電話  0800 - 885 - 315
    p 隱私權政策
    p
      | Copyrights © 2024 六書堂數位學習
      br.d-md-none
      | LIOSHUTAN. All Rights Reserved
  button.scrollTop(type="button" @click="scrollTop()")
    img(src="@/assets/newYear_2024/scrollTo.svg" alt="scrollTo")
    img(src="@/assets/newYear_2024/scrollTo-hover.svg" alt="scrollTo")
</template>

<script>
import Banner from './components/Banner.vue';
import TwoActive from './components/TwoActive.vue';
import Ribbons from './components/Ribbons.vue';
import FeatureComparison from './components/FeatureComparison.vue';
import TestYourRep from './components/testYourRep.vue';
import LightBox from './components/LightBox.vue';
import Carousel from './components/Carousel.vue';

import sweetalert from 'sweetalert2';
import moment from 'moment';

export default {

  name: 'Anniversary2023',

  components: {
    Banner,
    TwoActive,
    TestYourRep,
    Ribbons,
    FeatureComparison,
    LightBox,
    Carousel,
  },

  data() {
    return {
      deadlines: '2024-03-01 00:00:00',
      deadlinesAfter: false,
      activeTab: 'books',
    };
  },
  created() {
    // 時間到了會下架周年慶，banner 第一筆
    this.deadlinesAfter = moment(new Date()).isAfter(this.deadlines);
  },
  mounted() {
    if (this.deadlinesAfter) {
      sweetalert.fire({
        title: '活動時間已結束，歡迎至官網查看最新活動',
        icon: 'warning',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 3000,
      }).then(() => {
        this.$router.replace({ name: 'home' });
      });
    }
  },
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';

main {
  position: relative;
}

#header-2024 {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: rgba(255, 255, 255, 0.60);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  .logo_text {
    display: none;
    @media screen and (min-width: 991px) {
      display: block;
    }
    a {
      >div {
        color: #505050;
      }
    }
  }
}

.header-2024 {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  margin: 0 auto;

  @media screen and (min-width: 992px) {
    justify-content: space-between;
  }
}

#header-2024 .logo {
  float: left;
}

#header-2024 .logo img {
  padding: 0;
  /* margin: -10px; */
  width: 145px;
}

.gifts {
  padding-top: 40px;
  background-image:
    url('../../../assets/anniversary_2023/gifts/bg-character.svg'),
    // url('../../../assets/anniversary_2023/gifts/bg-people.svg'),
    linear-gradient(180deg, #8BD8EF 52.08%, #BCFBFF 100%);
  background-size: 1919px 587px, cover;
  background-repeat: no-repeat;
  background-position: 50% 0, 100% 100%;
  padding-bottom: 40px;

  @include md {
    background-size: 1919px 587px, cover;
  }

  @include lg {
    padding-bottom: 80px;
  }

  @include xl {
    background-size: contain,  cover;
  }
}

.gifts-wrap {
  padding: 0 16px;
}

.gifts-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @include xxl {
    margin-bottom: 40px;
  }

  h2 {
    font-size: 20px;
    font-weight: 900;
    border-radius: 20px;
    background: #fff;
    line-height: 31px;
    border: 4px solid #006BCF;
    padding: 10px 14px;
    margin-bottom: 0;
  }

  img {
    width: 60px;
  }
}

.bg-grid {
  border-radius: 20px;
  padding: 20px 10px;
  background: url('../../../assets/newYear_2024/twoActive/bg-grid-card.svg') repeat center / 160px 180px;
  max-width: 920px;
  margin: 0 auto;

  @include xxl {
    max-width: 1200px;
    padding: 82px 10px 93px 10px;
  }
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c6f1ff;
  border-radius: 10px;
  padding: 16px 10px;
  max-width: 608px;
  margin: 0 auto 40px auto;

  @include sm {
    padding: 10px;
    border-radius: 100px;
  }

  @include xxl {
    max-width: 780px;
  }

  >ul {
    padding: 0;
    list-style: none;
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
    li:nth-child(2) {
      // margin: 0 10px;

      @include sm {
        // margin: 0 20px;
      }
    }

    li {
      @include sm {
        width: 50%;
      }

      &.active button {
        background: #006BCF;
        color: #fff;
      }
    }
  }

  button {
    width: 100%;
    appearance: none;
    border: none;
    outline: none;
    background: #fff;
    border-radius: 25px;
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 700;
    transition: all .4s ease;
    color: #35485A;

    @include sm {
      padding: 10px;
    }

    &:hover {
      background: #006BCF;
      color: #fff;
    }
  }
}

.gifts-lightbox {
  background: #c6f1ff;
  border-radius: 20px;
  // padding: 40px;
  padding: 10px;
  max-width: 608px;
  margin: 0 auto;

  @include xxl {
    max-width: 1000px;
  }
}

.analyze {
  padding: 40px;

  h2 {
    color: #fff;
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 2px;
  }

  p {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.4px;
  }

  img {
    width: 100%;
  }
}

.note {
  background: #FEFDE1;
  border-radius: 20px;
  padding: 20px 16px;
  line-height: 120%;
  max-width: 920px;
  margin: 40px auto 0 auto;

  @include md {
    padding: 40px;
  }

  @include xxl {
    max-width: 1200px;
  }

  h2 {
    font-weight: 900;
    font-size: 20px;
    line-height: 145%;
    // margin-bottom: 40px;
  }
}

.note-item {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  h3 {
    font-weight: 900;
    font-size: 18px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

footer {
  background: #333333;
  color: #fff;
  font-size: 14px;
  padding: 28px 0;
  text-align: center;

  ul {
    list-style: none;
    padding: 0;

    @include md {
      max-width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      li {
        padding: 0 12px;
      }
    }
  }

  a {
    color: #fff;
  }
}

.scrollTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  z-index: 20;
  @include md {
    bottom: 40px;
  }
  @include lg {
    bottom: 40px;
    right: 40px;
  }

  &:hover {
    img:nth-child(1) {
      display: none;
    }

    img:nth-child(2) {
      display: block;
    }
  }

  img:nth-child(1) {
    width: 100px;

    @include lg {
      width: 150px;
    }
  }

  img:nth-child(2) {
    display: none;
    width: 100px;

    @include lg {
      width: 150px;
    }
  }
}</style>
