<template lang="pug">
form.signUp(:class="styleData")
    h2 報名領取
    p.text-center
        span.drawingPoint 英文能力分析報告
        | ＆ 實戰乾貨

    .signUp-form
        .signUp-input
            .icon
                img(src="@/assets/newYear_2024/icon/person.svg" alt="person")
            input(type="text" v-model="formUpper.parentName" placeholder="家長姓名 (必填)")
        .signUp-input
            .icon
                img(src="@/assets/newYear_2024/icon/phone.svg" alt="phone")
            input(type="text" v-model="formUpper.parentPhone" placeholder="家長手機 (必填)")
        .signUp-input
            .icon
                img(src="@/assets/newYear_2024/icon/mail.svg" alt="mail")
            input(type="text" v-model="formUpper.parentEmail" placeholder="家長信箱 (必填)")
        .signUp-input
            .icon
                img(src="@/assets/newYear_2024/icon/key.svg" alt="key")
            input(type="text" v-model="formUpper.keyword" placeholder="通關密語")
        .materials
            div(class="w-50")
              picture
                source(srcset="@/assets/newYear_2024/form/gift1-pc.png" media="(min-width: 1024px)")
                source(srcset="@/assets/newYear_2024/form/gift1-tablet.png" media="(min-width: 768px)")
                img(src="@/assets/newYear_2024/form/gift1-tablet.png" alt="家長必讀雙語兒童養成辦法")
            div(class="w-50")
              picture
                source(srcset="@/assets/newYear_2024/form/gift2-pc.png" media="(min-width: 1024px)")
                source(srcset="@/assets/newYear_2024/form/gift2-tablet.png" media="(min-width: 768px)")
                img(src="@/assets/newYear_2024/form/gift2-tablet.png" alt="劍橋兒童英檢題庫")
        .privacy
            input(type="checkbox" class="d-none" id="privacy" v-model="formUpper.privacyPolicyCheck")
            label(for="privacy")
                | 我同意 WUWOW 線上英文
                router-link(:to="{path:privacyUrl}",target="_blank") 隱私權政策
    button(class="signUp-btn" type="button" @click="submitForm(formUpper)") 免費搶先預約
    p.contactNumber
        span 0800 885 315
        | 撥打專線，立即有專人為您服務
</template>

<script>
import lioshutanApi from '@/api';
import { checkEmailFormat, checkPhoneFormat } from '@/utils/verify';
import { getEventMediaSource } from '@/utils/mediaSourse';
import httpCodeConstants from '@/constants/httpCode';
import { alertFailedMessage } from '@/utils/sweetAlert.js';

export default {
  name: 'SingForm',

  props: {
    styleData: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      privacyUrl: '/privacy',
      formUpper: {
        parentName: '',
        parentPhone: '',
        parentEmail: '',
        keyword: '',
        privacyPolicyCheck: true,
      },
    };
  },

  methods: {
    fieldCheck(email, phone, privacyPolicyCheck, formdata) {
      // let fulfilled = true;
      // Object.values(formdata).forEach((data) => {
      //   if (data === '') {
      //     fulfilled = false;
      //   }
      // });
      // if (!fulfilled) {
      //   alertFailedMessage('似乎還有些欄位尚未填寫完畢');
      //   return false;
      // }

      // 只檢查家長姓名
      if (!formdata.parentName) {
        alertFailedMessage('請留下留下您姓名');
        return false;
      }
      if (!checkEmailFormat(email)) {
        alertFailedMessage('可以再幫我確認一次您的電子郵件帳號嗎');
        return false;
      }
      if (!checkPhoneFormat(phone)) {
        alertFailedMessage('可以再幫我確認一次您的電話號碼嗎');
        return false;
      }
      if (!privacyPolicyCheck) {
        alertFailedMessage('可以再幫我確認一下隱私權政策及學習約定條款嗎');
        return false;
      }
      return true;
    },
    getNoteInfo(formdata) {
      let noteInfo = '';

      const queryGid = this.$route.query.gid;
      if (queryGid) {
        noteInfo += `gid=${queryGid} `;
      }

      // 必填
      if (formdata.parentName) {
        noteInfo += `學員家長姓名: ${formdata.parentName}`;
      }

      // 選填
      if (formdata.keyword) {
        noteInfo += ` | 通關密語: ${formdata.keyword}`;
      }
      // if (formdata.studentChineseName) {
      //   noteInfo += ` | 學員中文姓名: ${formdata.studentChineseName}`;
      // }
      // if (formdata.studentEnglishName) {
      //   noteInfo += ` | 學員英文姓名: ${formdata.studentEnglishName}`;
      // }
      // if (formdata.studentAge) {
      //   noteInfo += ` | 學員年齡: ${formdata.studentAge}`;
      // }
      return noteInfo;
    },
    hasLeaveMessage() {
      localStorage.setItem('hasWritedPhone', true);
    },
    async submitForm(formdata) {
      const fieldChecked = this.fieldCheck(formdata.parentEmail, formdata.parentPhone, formdata.privacyPolicyCheck, formdata);
      if (fieldChecked) {
        const mediaSource = getEventMediaSource(this.$route.path, this.$route.query);
        const noteInfo = this.getNoteInfo(formdata);
        const params = {
          name: formdata.parentName, // 原為學生姓名，改為家長中文姓名
          phone: formdata.parentPhone, // jr丟入家長的電話
          email: formdata.parentEmail, // jr丟入家長的email
          note: noteInfo, // 其餘資訊總和
          media_source: mediaSource,
        };
        const result = await lioshutanApi.common.setMediaSource(params);
        // if (result.data.data === 'freecoins_cvq') {
        //   this.savePhoneNumber(params.phone);
        // } else if (result.data.data == null && localStorage.getItem('setFreecoinsCvq')) {
        //   localStorage.removeItem('setFreecoinsCvq');
        // }
        if (result.status === httpCodeConstants.SUCCESS) {
          this.hasLeaveMessage();
          console.log(this.$route.query.m);
          switch (true) {
            case this.$route.query.m === 'eric_thank_you':
              this.$router.push({ name: 'newyear-eric-thank' });
              break;
            case /ad__eric/.test(this.$route.query.m):
              this.$router.push({ name: 'newyear-eric-thank' });
              break;
            case /eric/.test(this.$route.query.m):
              this.$router.push({ name: 'newyear-eric-thank' });
              break;
            default:
              this.$router.push({ name: 'newyear-thank' }); // 通往感謝頁
              break;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.signUp {
    border-radius: 20px;
    background: #fff;
    padding: 20px;
    max-width: 100%;
    @include md {
        width: 100%;
        max-width: 500px;
        // margin: 0 auto;
        padding: 27px;
    }

    @include lg {
        // max-width: 480px;
        // width: 480px;
        flex-grow: 1;
        flex-shrink: 0;
    }

    @include xl {
        flex-shrink: 0;
    }

    >h2 {
        text-align: center;
        font-weight: 900;
        font-size: 18px;
        line-height: 26.1px;
        margin-bottom: 20px;
        @include rwd-font(18px, 28px, 28px);
        @include rwd-lh(145%, 135%, 135%);
    }

    >p {
        font-weight: 700;
    }
}

.drawingPoint {
    display: inline-block;
    position: relative;
    background: linear-gradient(transparent 70%, #4CAED9 0%);
}

.signUp-input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #006bcf;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 27px;
    input {
        appearance: none;
        border: none;
        outline: none;
        padding: 8px 6px;
        flex-grow: 1;
        width: 100%;
    }
}

// // 2AB291
// .green .signUp-input {
//     border: 1px solid #2AB291;
// }

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-right: 1px solid #006bcf;
    // img:first-child {
    //     display: block;
    // }
    // img:last-child {
    //     display: none;
    // }
}

// .green .icon {
//     border-right: 1px solid #2AB291;
//     img:first-child {
//         display: none;
//     }
//     img:last-child {
//         display: block;
//     }
// }

.materials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    div{
      text-align: center;
      img {
        max-width: 100%;
      }
      &:nth-child(1){
        img{
          width: 159px;
          @include md {
            width: 183px;
          }
          @include lg{
            width: 195px;
          }
        }
      }
      &:nth-child(2){
        img{
          width: 129px;
          @include md {
            width: 153px;
          }
          @include lg{
            width: 161px;
          }
        }
      }
    }

}

.privacy {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #505050;
    line-height: 150%;
    margin-bottom: 24px;
    cursor: pointer;

    a {
        color: #006bcf;
        font-size: 14px;
    }

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        user-select: none;
    }

    label::before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 2px;
        margin-right: 4px;
        margin-bottom: 0;
        border: 1px solid #505050;
    }

    input:checked~label::before {
        background: #006bcf;
        border: 1px solid #006bcf;
    }

    label::after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 6px;
        height: 10px;
        left: 6px;
        top: 50%;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        transform: translate(-23%, -68%) rotate(45deg);
    }
}

// .green .privacy {
//     a {
//         color: #2AB291;
//     }

//     input:checked~label::before {
//         background: #2AB291;
//         border: 1px solid #2AB291;
//     }
// }

.signUp-btn {
    appearance: none;
    border: none;
    outline: none;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 25px;
    background: #006bcf;
    color: #fff;
    padding: 10px 0;

    &:hover {
        background: lighten(#006bcf, 10%);
    }
    &:disabled {
        background: rgba(205, 205, 205, 1);
    }
}

// .green .signUp-btn {
//     background: #2AB291;

//     &:hover {
//         background: lighten(#2AB291, 10%);
//     }
//     &:disabled {
//       background: rgba(205, 205, 205, 1);
//     }
// }

.signUp>.contactNumber {
    margin-top: 20px;
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.84px;

    span {
        color: #006bcf;
        margin-right: 10px;
    }
}
// .signUp.green >.contactNumber span{
//     color: #2AB291;
// }
</style>
