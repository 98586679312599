<template lang="pug">
.learnImpressions
  h2.mb-5
    | WUWOW開課超過
    span.light-text 500,000
    | 堂！
    br
    | 超過
    span.light-text 6600
    |  位學員找到了
    br
    | 適合自己的學英文方式!

  //- div( class="swiper-pc")
  //-   swiper(:options="swiperOption" )
  //-     swiper-slide(v-for="(group , index) in feedBackData")
  //-       div(class="feedback-grid")
  //-         div(v-for="(feedback , index) in group"  :key="index" class="feedback-card")
  //-           div(class="feedback-box")
  //-             div
  //-               img(:src="feedback.image")
  //-             div
  //-               h3 {{ feedback.vip }}
  //-               p {{ feedback.content }}
  //-     .swiper-button-prev.swiper-button-prev-pc(slot="button-prev")
  //-     .swiper-button-next.swiper-button-next-pc(slot="button-next")
  //-   <div class="swiper-pagination swiper-pagination-pc" slot="pagination"></div>

  div(class="swiper-tablet")
    swiper(:options="swiperOptionTablet")
      swiper-slide(v-for="(feedback , index) in feedBackDataPhone" class="feedback-card")
        div(class="feedback-box")
          div
            img(:src="feedback.image")
          div
            h3 {{ feedback.vip }}
            p {{ feedback.content }}
      .swiper-pagination.swiper-pagination-tablet.text-center(slot="pagination")
      .swiper-button-prev.swiper-button-prev-tablet(slot="button-prev")
      .swiper-button-next.swiper-button-next-tablet(slot="button-next")
  div(class="swiper-phone")
    swiper(
      :options="swiperOptionPhone"
    )
      swiper-slide(v-for="(feedback , index) in feedBackDataPhone" class="feedback-card")
        div(class="feedback-box")
          div
            img(:src="feedback.image")
          div
            h3 {{ feedback.vip }}
            p {{ feedback.content }}
      .swiper-pagination.swiper-pagination-phone.text-center(slot="pagination")
      .swiper-button-prev.swiper-button-prev-phone(slot="button-prev")
      .swiper-button-next.swiper-button-next-phone(slot="button-next")
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {

  name: 'Carousel',

  components: {
    swiper,
    swiperSlide,
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 100,
        loop: true,
        speed: 1000,
        pagination: {
          el: '.swiper-pagination-pc',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next-pc',
          prevEl: '.swiper-button-prev-pc',
        },
        // autoplay: {
        //   disableOnInteraction: false,
        //   delay: 5000,
        // },
      },
      swiperOptionTablet: {
        slidesPerView: 3,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        speed: 1000,
        pagination: {
          el: '.swiper-pagination-tablet',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next-tablet',
          prevEl: '.swiper-button-prev-tablet',
        },
        // autoplay: {
        //   disableOnInteraction: false,
        //   delay: 5000,
        // },
      },
      swiperOptionPhone: {
        slidesPerView: 1.5,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        speed: 1000,
        pagination: {
          el: '.swiper-pagination-phone',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next-phone',
          prevEl: '.swiper-button-prev-phone',
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
      },
      feedBackDataPhone: [
        {
          content: '實際評測15家兒童英文品牌，WUWOW 最大的優點是：專屬教材、師資互動佳、客服專業，各方面完善值得推薦！',
          vip: 'Dean  部落格個人品牌教練&顧問',
          image: require('@/assets/anniversary_2023/swiperItems/Dean.jpg'),
        },
        {
          content: '在 WUWOW 學英文是快樂的事，以前錯了都害羞到不敢繼續說，現在比較敢開口了！',
          vip: 'Abby 10歲',
          image: require('@/assets/anniversary_2023/swiperItems/Abby.jpg'),
        },
        {
          content: '為兒童專屬設計的線上英文學習系統，就像「英文保證班」讓人感到放心。',
          vip: 'Ocean 4歲',
          image: require('@/assets/anniversary_2023/swiperItems/Ocean.jpg'),
        },
        {
          content: '高互動加上時間彈性，教材設計還與108課綱同步，給孩子最生動的學習體驗。',
          vip: 'Lulumi 5歲',
          image: require('@/assets/anniversary_2023/swiperItems/Lulumi.jpg'),
        },
        {
          content: '英語是我們看世界的工具！很滿意 WUWOW JUNIOR 是因為教學課綱融合教育部108課綱，與學校脈動同步；課程分級對照國際 CEFR 及劍橋英檢標準。課程設計有趣、生動，充分引起孩子的興趣。',
          vip: 'Emmanuel 5 歲',
          image: require('@/assets/anniversary_2023/swiperItems/Emmanuel.jpg'),
        },
        // {
        //   content: 'Alice從以前的三催四請，現在上課時間一到就會乖乖就定位，也更願意開口說英文～',
        //   vip: 'Alice 媽媽',
        //   image: 'https://cdn3.wuwow.tw/arts/feedback5.png',
        // },
        // {
        //   content: '專業師資加上特別編排的課程教材，是我在搜集、試聽很多家線上英文課程後，為孩子選擇WUWOW JUNIOR最主要的原因。',
        //   vip: 'Noah 爸爸',
        //   image: 'https://cdn3.wuwow.tw/arts/feedback6.png',
        // },
        // {
        //   content: '改版後讓我最滿意的地方是增加了音檔，可以針對孩子比較弱的部分特別加強。',
        //   vip: 'Sophia 媽媽',
        //   image: 'https://cdn3.wuwow.tw/arts/feedback7.png',
        // },
        // {
        //   content: '很感謝Jr老師一直很有耐心引導，上課有問題的話客服也很積極的協助。',
        //   vip: 'Charlotte 媽媽',
        //   image: 'https://cdn3.wuwow.tw/arts/feedback8.png',
        // },
        // {
        //   content: '補習班上課時間固定，以前都花很多時間安排接送，轉到WUWOW JUNIOR的線上課程後時間彈性，效果也沒有打折！',
        //   vip: 'Mia 媽媽',
        //   image: 'https://cdn3.wuwow.tw/arts/feedback9.png',
        // },
        // {
        //   content: '身為一個二寶媽＋國小老師，小朋友喜不喜歡一個東西其實很明顯，一看就知道我們家小孩是真的喜歡在這邊上課。',
        //   vip: 'Eva 媽媽',
        //   image: 'https://cdn3.wuwow.tw/arts/feedback10.png',
        // },
        // {
        //   content: '老師經驗豐富，在我們家小孩注意力渙散的時候，都會馬上拉回來。',
        //   vip: 'Toby 媽媽',
        //   image: 'https://cdn3.wuwow.tw/arts/feedback11.png',
        // },
        // {
        //   content: '每堂課都有進度追蹤，讓我跟媽媽都蠻放心的。',
        //   vip: 'Liam 爸爸',
        //   image: 'https://cdn3.wuwow.tw/arts/feedback12.png',
        // },
      ],
      // feedBackData: [
      //   [
      //     {
      //       'content': '教材更改後，上課變得更多元、有趣，連陪同上課的我，也忍不住想參與整場課程！',
      //       'vip': 'Emily 媽媽',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback1.png',
      //     },
      //     {
      //       'content': '很喜歡現在教材除了一般英文學習外，還有不同主題的其他學習，讓我幫孩子課後複習可以有更多的延伸！',
      //       'vip': 'Ethan 爸爸',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback2.png',
      //     },
      //     {
      //       'content': '本來只是想讓他多一個學英文的管道，沒想到Isabella上了幾堂之後很喜歡，沒事就拉著我講英文。',
      //       'vip': 'Isabella 媽媽',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback3.png',
      //     },
      //     {
      //       'content': '從連ABC都不知道學到現在已經會一些簡單單字，新教材圖片生動活潑。',
      //       'vip': 'Olivia 爸爸',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback4.png',
      //     },
      //     {
      //       'content': 'Alice從以前的三催四請，現在上課時間一到就會乖乖就定位，也更願意開口說英文～',
      //       'vip': 'Alice 媽媽',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback5.png',
      //     },
      //     {
      //       'content': '專業師資加上特別編排的課程教材，是我在搜集、試聽很多家線上英文課程後，為孩子選擇WUWOW JUNIOR最主要的原因。',
      //       'vip': 'Noah 爸爸',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback6.png',
      //     },
      //   ],
      //   [
      //     {
      //       'content': '改版後讓我最滿意的地方是增加了音檔，可以針對孩子比較弱的部分特別加強。',
      //       'vip': 'Sophia 媽媽',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback7.png',
      //     },
      //     {
      //       'content': '很感謝Jr老師一直很有耐心引導，上課有問題的話客服也很積極的協助。',
      //       'vip': 'Charlotte 媽媽',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback8.png',
      //     },
      //     {
      //       'content': '補習班上課時間固定，以前都花很多時間安排接送，轉到WUWOW JUNIOR的線上課程後時間彈性，效果也沒有打折！',
      //       'vip': 'Mia 媽媽',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback9.png',
      //     },
      //     {
      //       'content': '身為一個二寶媽＋國小老師，小朋友喜不喜歡一個東西其實很明顯，一看就知道我們家小孩是真的喜歡在這邊上課。',
      //       'vip': 'Eva 媽媽',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback10.png',
      //     },
      //     {
      //       'content': '老師經驗豐富，在我們家小孩注意力渙散的時候，都會馬上拉回來。',
      //       'vip': 'Toby 媽媽',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback11.png',
      //     },
      //     {
      //       'content': '每堂課都有進度追蹤，讓我跟媽媽都蠻放心的。',
      //       'vip': 'Liam 爸爸',
      //       'image': 'https://cdn3.wuwow.tw/arts/feedback12.png',
      //     },
      //   ],
      // ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.light-text {
  color: #216DDB;
}

.learnImpressions {
  border: 2px solid #fff;
  border-radius: 20px;
  background: #cef1f9;
  padding: 40px 0;
  max-width: 920px;
  margin: 40px auto 0px auto;

  @include md {
    padding: 60px 20px;
  }

  @include xxl {
    max-width: 1200px;
  }

  h2 {
    font-weight: 900;
    text-align: center;
    @include rwd-font(18px, 24px, 32px);
    @include rwd-lh(145%, 135%, 135%);
  }
}

.swiper-pc {
  display: none;
  position: relative;
  overflow: hidden;
  padding: 40px 0;

  @include xxl {
    display: block;
  }

  .feedback-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }

  .feedback-card {

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-bottom: 20px;
    }

    // &:nth-child(4),
    // &:nth-child(5),
    // &:nth-child(6) {
    // }

  }
}

.swiper-phone {
  overflow: hidden;
  padding: 40px 0;
  display: block;

  @include md {
    display: none;
  }
}

.swiper-tablet {
  display: none;
  padding: 40px 0;
  overflow: hidden;

  @include md {
    display: block;
  }

  // @include xxl {
  //   display: none;
  // }

  // @media (min-width: 768px) and (max-width: 1240px) {
  // 	display: block;
  // }
}

.feedback-box {
  padding: 60px 24px 30px;
  background: #FFFEFA;
  border-radius: 40px;
  border: 2px solid #006BCF;
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 0;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  h3 {
    color: #006BCF;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.3;
    text-align: center;
  }

  p {
    color: #35485A;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
  }

  @include xxl {
    display: flex;
    gap: 20px;
    padding: 24px 28px;

    img {
      position: static;
      left: unset;
      top: unset;
      transform: translate(0%, 0%);
    }

    h3 {
      text-align: left;
    }
  }
}
</style>

<style lang="scss">
.swiper-pagination {
  width: 100%;
  bottom: -40px !important;
  .swiper-pagination-bullet {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    background: transparent;
    border: 2px solid #006BCF;
  }

  .swiper-pagination-bullet-active {
    background: #006BCF;
    width: 50px;
    border-radius: 50px;
  }
  &.swiper-pagination-pc {
    position: relative;
  }
}
</style>
