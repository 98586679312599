<template>
  <section class="test-your-rep">
    <div class="title-block">
      <img class="title-img" src="@/assets/newYear_2024/icon/loudspeaker.png" alt="loudspeaker">
      <div class="title-content">
        <h1>
          來測測你寶貝適合的
          <span>迪士尼經典人物專屬學習方式</span>
        </h1>
      </div>
    </div>
    <div class="test-content">
      <img src="@/assets/newYear_2024/test-your-rep/miki.png" alt="米奇">
      <img src="@/assets/newYear_2024/test-your-rep/bear.png" alt="維尼">
      <img src="@/assets/newYear_2024/test-your-rep/mini.png" alt="米妮">
      <img src="@/assets/newYear_2024/test-your-rep/pig.png" alt="小豬">
    </div>
    <img src="@/assets/newYear_2024/test-your-rep/one-year-disney-v2.png" class="one-year-disney" alt="一年份迪士尼+">
    <button type="button" class="go-to-test" @click="goToTest">前往測驗</button>
  </section>
</template>
<script>

export default {
  name: 'TestYourRep',
  methods: {
    goToTest() {
      this.$router.push({ path: '/event/psychological_test_dizney_characters' });
    },
  },
};

</script>

<style lang="scss" scoped>
@import '../style.scss';

.test-your-rep {
  min-height: 800px;
  padding: 40px 20px;
  overflow: hidden;
  background-image: url('../../../../assets/newYear_2024/test-your-rep/track-jr-bg.svg'), linear-gradient(180deg, #F1FDFF 0%, #F9FFEB 100%);
  ;
  background-repeat: no-repeat;
  /* 防止背景图重复 */
  background-position: bottom center;
  /* 背景图居中显示 */
  background-size: cover;
  /* 背景图覆盖整个容器，可能会被裁剪 */
  text-align: center;

  @include md {
    padding: 10px 20px;
  }

  .test-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 21px;
    margin: 60px auto;
    img {
      width: 150px;

      @media screen and (min-width: 768px) {
        width: 180px;

      }
    }
  }

  .one-year-disney {
    margin: 0 auto;
    width: 100%;
    max-width: 400px;
  }

  .go-to-test {
    margin: 60px auto;
    display: flex;
    width: 300px;
    height: 50px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    border-radius: 25px;
    background: #006BCF;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 155%;
    /* 21.7px */
    letter-spacing: 0.84px;
    &:hover{
      transition: all .3s;
      background: #004E97;
    }
  }
}
</style>
