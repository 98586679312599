var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_vm._m(0), _c('Banner'), _c('TwoActive'), _c('TestYourRep'), _c('FeatureComparison'), _c('Ribbons'), _c('div', {
    staticClass: "gifts"
  }, [_c('div', {
    staticClass: "gifts-wrap"
  }, [_c('div', {
    staticClass: "bg-grid"
  }, [_vm._m(1), _c('div', {
    staticClass: "tabs"
  }, [_c('ul', [_c('li', {
    class: {
      active: _vm.activeTab === 'books'
    }
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.activeTab = 'books';
      }
    }
  }, [_vm._v("家長必讀雙語"), _c('br', {
    staticClass: "d-sm-none"
  }), _vm._v("兒童養成辦法")])]), _c('li', {
    class: {
      active: _vm.activeTab === 'topics'
    }
  }, [_c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.activeTab = 'topics';
      }
    }
  }, [_vm._v("劍橋兒童"), _c('br', {
    staticClass: "d-sm-none"
  }), _vm._v("英檢題庫")])])])]), _c('div', {
    staticClass: "gifts-lightbox"
  }, [_c('LightBox', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ["books"].includes(_vm.activeTab),
      expression: "[ \"books\" ].includes(activeTab)"
    }],
    attrs: {
      "activeTab": _vm.activeTab
    }
  }), _c('LightBox', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: ["topics"].includes(_vm.activeTab),
      expression: "[ \"topics\" ].includes(activeTab)"
    }],
    attrs: {
      "activeTab": _vm.activeTab
    }
  })], 1)]), _c('Carousel'), _vm._m(2)], 1)]), _vm._m(3), _c('button', {
    staticClass: "scrollTop",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.scrollTop();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/newYear_2024/scrollTo.svg"),
      "alt": "scrollTo"
    }
  }), _c('img', {
    attrs: {
      "src": require("@/assets/newYear_2024/scrollTo-hover.svg"),
      "alt": "scrollTo"
    }
  })])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "fixed-top",
    attrs: {
      "id": "header-2024"
    }
  }, [_c('div', {
    staticClass: "container header-2024"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('div', {
    staticClass: "logo-wrap-wuwow"
  }, [_c('a', {
    staticClass: "scrollto",
    attrs: {
      "href": "https://www.wuwowjr.tw/",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("@/assets/newYear_2024/wuwow-jr-logo.svg"),
      "alt": "wuwow-jr-logo"
    }
  })])])]), _c('div', {
    staticClass: "logo_text"
  }, [_c('a', {
    attrs: {
      "href": "https://www.wuwowjr.tw/",
      "target": "_blank"
    }
  }, [_c('div', [_vm._v("0800 885 315 幫幫我 上英文 | 撥打專線，立即有專人為您服務")])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gifts-title"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/anniversary_2023/gifts/loudspeaker.svg"),
      "alt": "loudspeaker"
    }
  }), _c('h2', [_vm._v("好禮領取搶先看")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "note"
  }, [_c('h2', [_vm._v("活動注意事項")]), _c('div', {
    staticClass: "note-item"
  }, [_c('p', [_vm._v("1.活動期間 2024/2/1-2024/2/29。")]), _c('p', [_vm._v("2.活動期間內購買 WUWOW JUNIOR 課程可免費享有 1 年 Disney+ 標準版；若於合約期滿結束前退費解約則六書堂數位學習股份有限公司將追回此費用。")]), _c('p', [_vm._v("3. 贈品不得轉售(贈)、要求折價、變現，或要求其他優惠方式。")]), _c('p', [_vm._v("4. 符合活動資格者，將於合約開通後收到確認電子郵件，請於 2024/3/20 前依信件內容說明進行操作、請款，活動採實報實銷，逾期恕不受理。")]), _c('p', [_vm._v("5. 贈品領取相關問題，請來信 md6@lioshutan.com 並附上您的姓名/手機/E-mail，將有專人協助。")]), _c('p', [_vm._v("6.活動僅適用新春專案。")]), _c('p', [_vm._v("7.本公司保有解釋、修改、終止本活動之權利。")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', [_c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "mailto:service@wuwow.tw"
    }
  }, [_vm._v(" 聯絡信箱 service@wuwow.tw")])]), _c('li', [_c('a', {
    attrs: {
      "href": "tel:0800-885-315"
    }
  }, [_vm._v("免費諮詢電話 0800 - 885 - 315")])])]), _c('p', [_vm._v("隱私權政策")]), _c('p', [_vm._v("Copyrights © 2024 六書堂數位學習"), _c('br', {
    staticClass: "d-md-none"
  }), _vm._v("LIOSHUTAN. All Rights Reserved")])]);

}]

export { render, staticRenderFns }