var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [["topics", "books"].includes(_vm.activeTab) ? _c('div', {
    staticClass: "gallery"
  }, [_c('div', {
    staticClass: "gallery-pic",
    class: {
      disabled: _vm.materials[_vm.activeTab][_vm.picIndex].disabled
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.materials[_vm.activeTab][_vm.picIndex].src,
      "alt": "教材"
    }
  }), _c('button', {
    staticClass: "prev",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.pre();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "arrow-left"
    }
  })], 1), _c('button', {
    staticClass: "next",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.next();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "arrow-right"
    }
  })], 1)])]) : _vm._e(), _c('div', {
    staticClass: "thumb-row"
  }, _vm._l(_vm.materials[_vm.activeTab], function (item, index) {
    return _c('button', {
      key: item.id,
      staticClass: "thumb",
      class: {
        active: _vm.picIndex === index
      },
      attrs: {
        "type": "button",
        "disabled": item.disabled
      },
      on: {
        "click": function ($event) {
          _vm.picIndex = index;
        }
      }
    }, [_c('img', {
      attrs: {
        "src": item.src,
        "alt": "教材"
      }
    })]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }