var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "ribbons"
  }, [_c('div', {
    staticClass: "celebrate-people-block"
  }, [_c('img', {
    staticClass: "flag-left",
    attrs: {
      "src": require("@/assets/anniversary_2023/ribbons/flag-left.svg")
    }
  }), _c('img', {
    staticClass: "flag-right",
    attrs: {
      "src": require("@/assets/anniversary_2023/ribbons/flag-right.svg")
    }
  }), _c('SignForm')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }