var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "learnImpressions"
  }, [_vm._m(0), _c('div', {
    staticClass: "swiper-tablet"
  }, [_c('swiper', {
    attrs: {
      "options": _vm.swiperOptionTablet
    }
  }, [_vm._l(_vm.feedBackDataPhone, function (feedback, index) {
    return _c('swiper-slide', {
      staticClass: "feedback-card"
    }, [_c('div', {
      staticClass: "feedback-box"
    }, [_c('div', [_c('img', {
      attrs: {
        "src": feedback.image
      }
    })]), _c('div', [_c('h3', [_vm._v(_vm._s(feedback.vip))]), _c('p', [_vm._v(_vm._s(feedback.content))])])])]);
  }), _c('div', {
    staticClass: "swiper-pagination swiper-pagination-tablet text-center",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }), _c('div', {
    staticClass: "swiper-button-prev swiper-button-prev-tablet",
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  }), _c('div', {
    staticClass: "swiper-button-next swiper-button-next-tablet",
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  })], 2)], 1), _c('div', {
    staticClass: "swiper-phone"
  }, [_c('swiper', {
    attrs: {
      "options": _vm.swiperOptionPhone
    }
  }, [_vm._l(_vm.feedBackDataPhone, function (feedback, index) {
    return _c('swiper-slide', {
      staticClass: "feedback-card"
    }, [_c('div', {
      staticClass: "feedback-box"
    }, [_c('div', [_c('img', {
      attrs: {
        "src": feedback.image
      }
    })]), _c('div', [_c('h3', [_vm._v(_vm._s(feedback.vip))]), _c('p', [_vm._v(_vm._s(feedback.content))])])])]);
  }), _c('div', {
    staticClass: "swiper-pagination swiper-pagination-phone text-center",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }), _c('div', {
    staticClass: "swiper-button-prev swiper-button-prev-phone",
    attrs: {
      "slot": "button-prev"
    },
    slot: "button-prev"
  }), _c('div', {
    staticClass: "swiper-button-next swiper-button-next-phone",
    attrs: {
      "slot": "button-next"
    },
    slot: "button-next"
  })], 2)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "mb-5"
  }, [_vm._v("WUWOW開課超過"), _c('span', {
    staticClass: "light-text"
  }, [_vm._v("500,000")]), _vm._v("堂！"), _c('br'), _vm._v("超過"), _c('span', {
    staticClass: "light-text"
  }, [_vm._v("6600")]), _vm._v(" 位學員找到了"), _c('br'), _vm._v("適合自己的學英文方式!")]);

}]

export { render, staticRenderFns }