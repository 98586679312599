// 產品名稱
import commonConstants from '@/constants/common';

// 用於網址位於 event/ 之下的登陸頁
// fullPath 參數要在所使用 view 裡帶入 this.$route.fullPath
// routeQuery 參數要在所使用 view 裡帶入 this.$route.query
// mediaSourceRef 帶入藥賦值的響應式資料
export function getEventMediaSource(path, routeQuery, mediaSourceRef) {
  // 從網址拿出 /event 後面的子網域
  // 空值拿掉
  const routeArr = path.split('/').filter(item => item);
  // 移除第一個空字串
  // const eventIndex = routeArr.indexOf('event');
  const regex = /\?/;
  const eventName = routeArr
    .filter((item) => !['event'].includes(item))
    .join('_')
    .replace(regex, '_')
    .split('_')
    .filter(item => item)
    .filter(item => !/\m=/.test(item))
    .join('_');

  // let eventName = '';
  // if (eventIndex >= 0) {
  //   // 去除查詢字串 ?m=XXX
  //   // 這邊不能這樣寫
  //   const questionIndex = routeArr.filter((item) => regex.test(item) || !['event'].includes(item));
  //   console.log(questionIndex);
  //   eventName = routeArr[eventIndex + 1].split('?')[0];
  // }
  // m = {媒體來源名稱}
  let mediaSource = '';
  if (routeQuery.m) {
    // 有填 query string 組裝格式 ==> 「產品名(jr / wuwow)」_「活動名稱(檔名/資料夾名)_「?m=媒體來源」
    mediaSource = `${commonConstants.MEDIA_SOURCE.NAME}_${eventName}_${routeQuery.m}`;
  } else {
    // 沒有填 query string
    mediaSource = `${commonConstants.MEDIA_SOURCE.NAME}_${eventName}`;
  }

  if (mediaSourceRef) {
    mediaSourceRef = mediaSource;
  }
  return mediaSource;
}
