<template lang="pug">
section.banner
  .banner-wrap
    .banner-title
      img.d-inline-block(src="@/assets/newYear_2024/banner/title.svg" alt="雙語兒童養成技")
      p 超越時空、多元有趣的 WUWOW Jr.<br>營造輕鬆愉快沈浸式英文環境！
      .banner-rewards
        img(src="@/assets/newYear_2024/banner/disney.svg" alt="一年份Disney+")

    SignForm

  .banner-disney
    div(class="title-block")
      img(class="title-img" src="@/assets/newYear_2024/icon/loudspeaker.png" alt="loudspeaker")
      div(class="title-content")
        h1
          | 追尋夢想，買就送Disney+

    .disney-box
      h2 購買任一學習方案，<br>贈送 <span>1 年 Disney+</span>標準方案
      p 開啟兼顧<span>「有效</span>」與<span>「有趣」</span>的英文學習旅程，<br>讓休閒生活也能很英文！
      img(src="@/assets/newYear_2024/banner/disney-free-l.png" alt="disney-free")
    //- form.signUp
    //-   h2 報名領取
    //-   p.text-center
    //-     span.drawingPoint 英文能力分析報告
    //-     | ＆英檢題庫
    //-     br
    //-     | 再抽不
    //-     span.drawingPoint 限航點來回機票
    //-   .signUp-form
    //-     .signUp-input
    //-       .icon
    //-         img(src="@/assets/anniversary_2023/icon/person.svg" alt="person")
    //-       input(type="text" placeholder="家長姓名 (必填)")
    //-     .signUp-input
    //-       .icon
    //-         img(src="@/assets/anniversary_2023/icon/phone.svg" alt="phone")
    //-       input(type="text" placeholder="家長手機 (必填)")
    //-     .signUp-input
    //-       .icon
    //-         img(src="@/assets/anniversary_2023/icon/mail.svg" alt="mail")
    //-       input(type="text" placeholder="家長信箱 (必填)")
    //-     .materials
    //-       a(href="#" class="w-50")
    //-         img(class="w-100" src="@/assets/anniversary_2023/form/career.svg" alt="career")
    //-       a(href="#" class="w-50")
    //-         img(class="w-100" src="@/assets/anniversary_2023/form/ticket.svg" alt="ticket")
    //-     .privacy
    //-       input(type="checkbox" class="d-none" id="privacy")
    //-       label(for="privacy")
    //-         | 我同意 WUWOW 線上英文
    //-         a(href="#") 隱私權全政策
    //-   button(class="signUp-btn" type="button") 免費搶先預約
    //-   p.contactNumber
    //-     span 0800 885 315
    //-     | 撥打專線，立即有專人為您服務
</template>

<script>
import SignForm from './SignForm.vue';
export default {

  name: 'Anniversary2023Banner',

  components: { SignForm },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.banner {
  width: 100%;
  padding-bottom: 40px;
  background-repeat: no-repeat;
  background-image:
    url('../../../../assets/newYear_2024/banner/bg-m.png');
  background-position: 48% 0%;

  // background-position: 60% 98%, 60% -280px, 0% 50%;

  @include sm {
    // background-size: 768px 2354px;
    // background-position:  60% -280px, 0% 50%;
  }

  @include md {
    // background-size: 768px 2354px;
    // background-position:  98% -280px, 100% 100%;
    background-position: 48% 5%;
    padding-bottom: 80px;
  }

  @include lg {
    height: auto;
    background-image:
      url('../../../../assets/newYear_2024/banner/bg-l.png');
      background-position: center top;
    // background-size: 1920px 2004px;
    // background-position: 110% 0%, 0% 50%;
    // padding-bottom: 250px;
  }

  @include xxl {
    // background-position: 110% 0%, 0% 50%;
    padding-bottom: 3vw;

  }

  @include xxxl {
    background-size: cover;
    // background-size: 1920px 2004px, cover;
    // background-position:  100% 0%, 100% 100%;
  }
}

.banner-wrap {
  margin: 0 auto;
  padding: 40px 16px 0 16px;

  @include md {
    padding: 100px 40px 0 40px;
  }

  @include lg {
    display: flex;
  }

  @include xl {
    max-width: 1280px;
  }
}

.banner-title {
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @include lg {
    text-align: left;
  }

  >img {
    width: 220px;

    @include sm {
      width: 320px;
    }

    @include md {
      width: 420px;
    }
  }

  p {
    color: #006BCF;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 2px;
    font-size: 16px;

    @include sm {
      font-size: 20px;
    }

    @include md {
      font-size: 24px;
    }
  }
}

.banner-disney {
  margin: 0 auto;
  padding: 40px 16px 0 16px;
  text-align: center;
  @include md {
    padding: 40px 40px 0 40px;
  }

  @include xl {
    max-width: 1280px;
  }

  img {
    max-width: 100%;
  }
}

.disney-box {
  border: 4px solid #FFF;
  border-radius: 20px;
  background: rgba(242, 252, 255, 0.90);
  margin: 40px auto 0 auto;
  padding: 40px 16px 40px 16px;
  text-align: center;

  @include md {
    padding: 100px 40px 0 40px;
  }

  @include xl {
    max-width: 1280px;
  }

  h2 {
    font-weight: 900;
    letter-spacing: 0.06em;
    line-height: 1.3;
    text-align: center;
    font-size: 20px;
    @include md {
      font-size: 24px;
    }
    @include lg{
      font-size: 32px;
    }
  }
  p {
    font-weight: 900;
    letter-spacing: 0.06em;
    line-height: 1.3;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    @include md{
      margin-top: 53px;
      margin-bottom: 53px;
      font-size: 20px;
    }
    @include lg {
      margin-top: 40px;
      margin-bottom: 40px;

    }
  }
  span{
    color: #006BCF;
  }

  img {
    width: 90%;
    max-width: 606px;
  }
}

.banner-rewards {
  padding: 0 12px;
  margin-bottom: 32px;

  img {
    width: 100%;
    max-width: 280px;

  }

}

.signUp {
  border-radius: 20px;
  background: #fff;
  padding: 20px;

  @include md {
    max-width: 520px;
    margin: 0 auto;
    padding: 27px;
  }

  @include lg {
    // max-width: 480px;
    width: 480px;
    flex-grow: 1;
    flex-shrink: 0;
  }

  @include xl {
    flex-shrink: 0;
  }

  >h2 {
    text-align: center;
    font-weight: 900;
    font-size: 18px;
    line-height: 26.1px;
    margin-bottom: 10px;
    @include rwd-font(18px, 28px, 28px);
    @include rwd-lh(145%, 135%, 135%);
  }

  >p {
    font-weight: 700;
  }
}

.drawingPoint {
  display: inline-block;
  position: relative;
  background: linear-gradient(transparent 60%, #FFCB15 80%, transparent 100%, );
}

.signUp-input {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFCB15;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 27px;

  input {
    appearance: none;
    border: none;
    outline: none;
    padding: 8px 6px;
    flex-grow: 1;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-right: 1px solid #FFCB15;
}

.materials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  a {
    cursor: auto;
  }
}

.privacy {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #505050;
  line-height: 150%;
  margin-bottom: 24px;
  cursor: pointer;

  a {
    color: #FFCB15;
    font-size: 14px;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;
  }

  label::before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    margin-right: 4px;
    margin-bottom: 0;
    border: 1px solid #505050;
  }

  input:checked~label::before {
    background: #FF9700;
    border: 1px solid #FF9700;
  }

  label::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 6px;
    height: 10px;
    left: 6px;
    top: 50%;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    transform: translate(-23%, -68%) rotate(45deg);
  }
}

.signUp-btn {
  appearance: none;
  border: none;
  outline: none;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 25px;
  background: #FFCB15;
  color: #fff;
  padding: 10px 0;

  &:hover {
    background: lighten(#FFCB15, 10%);
  }
}

.signUp>.contactNumber {
  margin-top: 20px;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.84px;

  span {
    color: #FFCB15;
    margin-right: 10px;
  }
}
</style>
