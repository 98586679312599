<template lang="pug">
section.twoActive
  //- .imgBox
  //-   img(src="@/assets/newYear_2024/twoActive/top.svg" alt="top")
  .twoActive-wrap
    .twoActive-container
      div
        .title-block
          img.title-img(src='@/assets/newYear_2024/icon/loudspeaker.png' alt='loudspeaker')
          .title-content
            h1 雙語兒童養成辦法

        .learnReason-grid
          .learnReason-card
            div
              img(src='@/assets/newYear_2024/twoActive/grow1.png' alt="全英文環境")
              h3.learnReason-scondary 全英文環境
              img.dec(:src="require('@/assets/event/trial/dec.png')")
              p
                | 在家與學校創造全英語氛圍，
                br
                | 促進孩子在生活中輕鬆自然地
                br
                | 運用英文。
            span.leaf.leaf-M
            span.leaf.leaf-L
            span.leaf.leaf-S

          img(src='@/assets/newYear_2024/icon/plus.svg' alt='plus' style="margin:0 auto")
          .learnReason-card
            div
              img(src='@/assets/newYear_2024/twoActive/grow2.png')
              h3.learnReason-scondary 穩定高頻率學習
              img.dec(:src="require('@/assets/event/trial/dec.png')")
              p
                | 每週穩定、頻繁學習英文，養
                br
                | 成自然而然的語感。
            span.leaf.leaf-M
            span.leaf.leaf-L
            span.leaf.leaf-S

          img(src='@/assets/newYear_2024/icon/plus.svg' alt='plus' style="margin:0 auto")
          .learnReason-card
            div
              img(src='@/assets/newYear_2024/twoActive/grow3.png')
              h3.learnReason-scondary 有趣學習 愛上英文
              img.dec(:src="require('@/assets/event/trial/dec.png')")
              p
                | 利用英文卡通、音樂等活動，
                br
                | 培養孩子對英文的愛好，無壓
                br
                | 有趣學習。
            span.leaf.leaf-M
            span.leaf.leaf-L
            span.leaf.leaf-S
        .arrow-down
          img(src='@/assets/newYear_2024/icon/arrow-down.png' alt='arrow-down' )
        .junior-english
          img(src="@/assets/newYear_2024/twoActive/junior-english.png" alt="junior-english" )
</template>

<script>
export default {
  name: 'TwoActive',

  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style.scss';

.twoActive {
  overflow: hidden;
  margin-top: -20px;

  @include lg {
    margin-top: -3vw;
  }

  .imgBox {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #aee7f5;
      height: 10px;
    }

    img {
      width: 768px;

      @include md {
        width: 100%;
      }
    }
  }

  // background: linear-gradient(180deg, #94E0D3 52.08%, #B1F6D5 100%) no-repeat center / cover;

}

.twoActive-wrap {
  background-image:
    url('../../../../assets/newYear_2024/twoActive/top.png');
    background-repeat: no-repeat;
    background-size: 768px 103px;
    background-position: center top;
    padding-top: 103px;

    @include md {
      background-size: 1920px 258px;
      padding-top: 258px;
      background-repeat: repeat;
    }
}

.twoActive-container {
  position: relative;

  background-color: #aee7f5;
  background-image:
    url('../../../../assets/newYear_2024/twoActive/earth.svg'),
  ;
  background-repeat: no-repeat;
  background-size: 1920px 1446px;
  background-position: center 160%;

  @include md {
    padding: 0px 0 300px 0;
    background-position: center 119%;
  }

  @include lg {
    padding: 0px 0 300px 0;
    background-position: center -52%;
  }

  @include xxxl {
    background-image:
      url('../../../../assets/newYear_2024/twoActive/earth.svg'),

    ;
    background-repeat: no-repeat;
    background-position:
    center 100%, ;

    background-size:
      contain, // 地球
  }

  >div {
    padding: 0 16px;
    max-width: 1014px;
    margin: 0 auto;
  }

  padding: 0px 0 300px 0;

}

.twoActive-title {
  text-align: center;

  img {
    max-width: 100%;
  }
}

.twoActive-cards {
  padding: 0;
  margin: 0;
  padding-top: 52%;
  list-style: none;

  @include md {
    padding-top: 152px;
  }

  @include xxl {
    padding-top: 240px;
  }
}

.learnReason {
  &-grid {
    display: grid;
    margin-top: 20px;
    gap: 20px;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;

    @include lg {
      grid-template-columns: 1fr 40px 1fr 40px 1fr;
    }
  }

  &-scondary {
    color: #75CDEC;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 1/2;
  }

  &-card {
    padding: 20px 20px 40px 20px;
    border-bottom: 10px solid #75CDEC;
    box-shadow: 0px 0px 20px rgba(216, 200, 182, 0.3);
    border-radius: 30px;
    position: relative;
    background: linear-gradient(180deg, #FFFFFF 85.42%, #E7F9FF 100%);
    min-height: 185px;
    text-align: center;

    img {
      &:first-child {
        width: 160px;
      }
    }

    @include md {
      height: 375px;
    }

    .leaf {
      position: absolute;
      bottom: 0;
      background: url('~@/assets/event/trial/title-leaft.png') no-repeat center / 50% 50%;

      &-M {
        width: 50px;
        height: 50px;
        bottom: -14px;
      }

      &-S {
        width: 40px;
        height: 40px;
        right: 18%;
        bottom: -10px;
      }

      &-L {
        width: 70px;
        height: 70px;
        right: 22%;
        bottom: -20px;
      }
    }

    h3 {
      font-weight: 700;
      margin-bottom: 0;
      @include rwd-font(16px, 20px, 20px);
      @include rwd-lh(22px, 24px, 24px);
    }

    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      line-height: 1.55;
    }

    .dec {
      margin: 10px 0;
      max-width: 235px;
      width: 100%;
    }

    img {
      width: 70px;

      @media (min-width: 768px) {
        width: 160px;
      }
    }
  }
}

.arrow-down {
  text-align: center;
  margin: 20px 0;
}

.twoActive-card {
  max-width: 450px;
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  border: 6px solid #2AB291;

  @include md {
    max-width: 665px;
  }

  @include xxl {
    max-width: 1000px;
  }

  &-title {
    text-align: center;
    background: #2AB291;
    color: #fff;
    font-weight: 900;
    line-height: 135%;
    padding: 18px 8px;
    @include rwd-font(18px, 28px, 28px);
  }

  &-subTitle {
    text-align: center;
    text-align: center;
    font-weight: 900;
    background: url('../../../../assets/anniversary_2023/twoActive/card-title-point-sm.svg') no-repeat 90% 100% / 103px 19px;
    display: inline-block;
    padding: 0px 12px 12px 12px;
    @include rwd-font(18px, 28px, 28px);

    @include md {
      margin-bottom: 28px;
    }
  }

  &-main {
    background: #fff;
    padding: 80px 10px 40px 10px;
    position: relative;
    text-align: center;

    @include md {
      padding: 100px 30px 40px 30px;
    }

    @include xxl {
      padding: 160px 30px 40px 30px;
    }

    &::after,
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 50%;
      top: 0;
      aspect-ratio: 160 / 60;

      @include md {
        width: 40%;
      }
    }

    &::before {
      background: url('../../../../assets/anniversary_2023/twoActive/card-ribbon-left-sm.svg') no-repeat center / contain;
      left: -6px;
    }

    &::after {
      background: url('../../../../assets/anniversary_2023/twoActive/card-ribbon-right-sm.svg') no-repeat center / contain;
      right: -6px;
    }
  }

  &:nth-child(2) {
    margin-top: 20px;

    .twoActive-card-main {
      padding: 70px 10px 40px 10px;

      @include md {
        padding: 105px 10px 40px 10px;
      }
    }

    .twoActive-card-decoration {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-bottom: 20px;

      img {
        @include md {
          position: absolute;
          top: 0;
          z-index: 1;
        }

        @include xl {
          top: 20px;
        }

        &:nth-child(1) {
          width: 176px;
          left: 0;
          width: 136px;

          @include xxl {
            left: 10%;
            width: auto;
          }
        }

        &:nth-child(2) {
          width: 89px;
          right: 0;

          @include xxl {
            right: 10%;
          }
        }
      }
    }

    .twoActive-card-subTitle {
      text-align: center;
      text-align: center;
      font-weight: 900;
      background: url('../../../../assets/anniversary_2023/twoActive/card-title-point-sm.svg') no-repeat 90% 100% / 103px 19px;
      line-height: 31px;
      margin-bottom: 30px;
      @include rwd-font(18px, 28px, 28px);

      @include md {
        font-size: 24px;
        margin-bottom: 40px;
      }

      &.break {
        @include md {
          background: url('../../../../assets/anniversary_2023/twoActive/card-title-point-sm.svg') no-repeat 60% 100% / 103px 19px;
        }
      }
    }

    .twoActive-card-descriptive {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 40px;

      @include md {
        margin-bottom: 80px;
      }
    }

    .twoActive-step {
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 60px;

      @include lg {
        justify-content: center;
      }

      &-title {
        font-size: 18px;
        font-weight: 900;
        margin-bottom: 20px;
      }

      li {
        width: 50%;
        position: relative;

        @include md {
          width: 25%;
        }

        @include lg {
          max-width: 150px;
        }

        h4 {
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 20px;
        }

        img {
          width: 80px;
          margin-bottom: 20px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
        }

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 40px;

          @include md {
            margin-bottom: 0;
          }
        }

        &:nth-child(1),
        &:nth-child(3) {
          &::after {
            content: '';
            position: absolute;
            display: inline-block;
            background: url('../../../../assets/anniversary_2023/twoActive/stepArrow.svg') no-repeat center / contain;
            width: 21px;
            height: 24px;
            top: 32%;
            right: 0px;
            transform: translateX(50%);
          }
        }

        &:nth-child(2) {
          @include md {
            &::after {
              content: '';
              position: absolute;
              display: inline-block;
              background: url('../../../../assets/anniversary_2023/twoActive/stepArrow.svg') no-repeat center / contain;
              width: 21px;
              height: 24px;
              top: 32%;
              right: 0px;
              transform: translateX(50%);
            }
          }
        }
      }
    }
  }
}

.twoActive-eligibility {
  padding: 20px 0;
  background: #DAF1FE;
  border-radius: 20px;

  @include md {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include lg {
    justify-content: center;
  }

  &-title {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 20px;
  }

  &-subtitle {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  >div {
    @include md {
      max-width: 227px;
      width: 50%;
      padding: 0 12px;
    }

    p {
      text-align: center;
    }

    img {
      display: inline-block;
      width: 180px;
      margin-bottom: 20px;

      @include md {
        width: 100%;
      }
    }

    &:nth-child(1) {
      position: relative;
      margin-bottom: 70px;

      @include md {
        margin-bottom: 0;
      }

      @include lg {
        margin-right: 70px;
      }

      &::after {
        content: '';
        position: absolute;
        background: url('../../../../assets/anniversary_2023/twoActive/eligibility-arrow.svg') no-repeat center / contain;
        width: 51px;
        height: 51px;
        left: 50%;
        top: 100%;
        transform: translate(-50%, 16px);

        @include md {
          left: 100%;
          top: 25%;
          transform: translate(90%, 100%) rotate(-90deg);
        }

        @include lg {
          transform: translate(35%, 100%) rotate(-90deg);
        }
      }
    }
  }
}

.light-text {
  color: #2AB291;
}

.phone-gift {
  text-align: center;
  margin-bottom: 20px;

  @include md {
    border-radius: 20px;
    background: #EFFAFD;
    position: relative;
  }

  >img {
    width: 200px;
    margin-bottom: 20px;
    z-index: 2;

    @include md {
      margin-bottom: 0;
      position: absolute;
      left: -5%;
      width: 140px;
    }

    @include lg {
      width: 120px;
      left: -5%;
    }

    @include xl {
      left: -5%;
    }

    @include xxl {
      left: 9%;
    }
  }

  &-descriptive {
    background: #EFFAFD;
    border-radius: 20px;
    padding: 20px 0;
    font-weight: 900;
  }
}

.shopper-day {
  text-align: center;

  @include md {
    border-radius: 20px;
    background: #EFFAFD;
    position: relative;
  }

  >img {
    width: 215px;
    margin-bottom: 20px;

    @include md {
      margin-bottom: 0;
      position: absolute;
      bottom: 0;
      right: -5%;
      width: 140px;
    }

    @include xxl {
      right: 10%;
    }
  }

  &-descriptive {
    background: #EFFAFD;
    border-radius: 20px;
    padding: 20px 0;
    font-weight: 900;
  }
}

.note {
  background: #FFF2CE;
  padding: 8px 0;
  font-weight: 700;
  border-radius: 10px;
}

.reservation-btn {
  appearance: none;
  border: none;
  outline: none;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 25px;
  background: #2AB291;
  color: #fff;
  padding: 10px 0;
  max-width: 300px;
  margin: 20px auto 0 auto;

  &:hover {
    background: lighten(#2AB291, 10%);
  }
}

.junior-english {
  margin: 0 auto;
  text-align: center;
  width: 343px;
  max-width: 100%;
  img{
    max-width: 100%;
  }
  @include md {
    width: auto;
  }
}</style>
